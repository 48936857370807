/* GEF Portal Custom CSS */

#content {
  width: calc(100% - 105px);
  max-width: 1324px;
  min-height: 550px;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 120px;
  background-color: white;
}

#navigation {
  position: absolute;
  left: 40%;
  bottom: 0px;
  z-index: 0;
  background-color: white;
  opacity: 100%;
  transition: all 0.2s;
  padding-bottom: 0px;
  min-width: 600px;
  max-width: 600px;
  max-height: 150px;
  min-height: 150px;

  .main-menu-item {
    display: inline-block;
    margin: 0 calc(1.1vw + 12px) 0 calc(1.1vw + 12px);
    font-size: 1.5rem;
    height: 36px;
    line-height: 1.5;
    transition: all 150ms ease-in-out;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }
}


/*KBE Style*/

/*Table*/
div.table-1 {
  max-width: 800px;
  margin-top: 36px;
  margin-bottom: 36px;
  position: relative;
}

div.table-container {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 800px;
}

h3 {
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 18px 0;
  max-width: 790px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  width: calc(100% - 100px);
}

/* Table-Cell */
td {
  vertical-align: top;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 16px;
  line-height: 18px;
  border-bottom: 1px solid rgba(182, 182, 182, 1);
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 790px;
}

/* Table-Cell (Number) */
td.n {
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  vertical-align: middle;
}

/* Table-Cell (Sand BG) */
td.sand {
  background-color: rgba(250, 241, 227, 1);
}

/* Table Caption */
th {
  vertical-align: bottom;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  line-height: 120%;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 790px;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Table Row */
tr {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}

/* Table-Cell (First Column) */
td.title-row {
  color: rgba(112, 112, 112, 1);
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 790px;
  vertical-align: middle;
  max-width: 253px;
}

/* Table */
table.type-1 {
  width: 100%;
  margin-bottom: 15px;
  max-width: 800px;
}

/* Table Caption (align-right) */
th.a-right {
  text-align: right;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  line-height: 120%;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 790px;
}

div.table-caption {
  font-size: 13px;
  line-height: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0 42px;
  max-width: 500px;
  width: calc(100% - 100px);
}

div.sliding-nav-top {
  position: absolute;
  display: none;
  white-space: nowrap;
  right: 0;
  top: 25px;
}

div.sliding-nav-bottom {
  position: absolute;
  white-space: nowrap;
  bottom: 0;
  right: 0;
  display: none;
}


@media screen and (max-width: 1039px) {

  h3 {
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    margin: -3px 0 12px -1px;
  }

}

@media screen and (max-width: 767px) {

  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: -2px 0 12px 0px;
  }

  div.sliding-nav-top {
    display: block;
  }

  div.sliding-nav-bottom {
    display: block;
  }

}

/*Table end*/

/*Link style*/

a.text-link {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 1);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  line-height: 120%;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  display: inline;
}

a.text-link:hover, a.text-link:focus {
  border-bottom: 1px solid rgba(234, 22, 31, 1);
}


@media screen and (max-width: 1039px) {

  a.text-link {
    position: relative;
  }

}

a {
  text-decoration: underline;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}

a:hover, a:focus {
  text-decoration: underline;
  text-decoration-color: #EA161F;
  color: rgba(0, 0, 0, 1);
}

/*End Link style*/

/* Header Benutzermenu rechts oben */
.dropdown-menu a {
  text-decoration: none!important;
}

.dropdown-menu a:hover, a:focus {
  text-decoration: underline!important;
}

.dropdown button::after {
  content: none;
}

/*Headings */
h1 {
  color: rgba(0, 0, 0, 1);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  margin: 0 0 48px -3px;
  max-width: 790px;
  font-size: 54px;
  line-height: 62px;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
}


@media screen and (max-width: 1039px) {

  h1 {
    font-size: 48px;
    line-height: 56px;
    margin: -9px 0 24px -2px;
  }

}

@media screen and (max-width: 767px) {

  h1 {
    font-size: 36px;
    line-height: 42px;
    margin: -6px 0 18px -2px;
  }

}

h2 {
  font-size: 34px;
  line-height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  margin: -6px 0 18px -2px;
  max-width: 790px;
  color: rgba(0, 0, 0, 1);
}


@media screen and (max-width: 1039px) {

  h2 {
    font-size: 30px;
    line-height: 35px;
    margin: -7px 0 15px -1.5px;
  }

}

@media screen and (max-width: 767px) {

  h2 {
    font-size: 26px;
    line-height: 30px;
    margin: -4px 0 16px -1px;
  }

}

/*End Headings*/

/* Form Elements*/
div.next-back-buttons {
  margin-top: 36px;
  max-width: 800px;
}

div.valid.floating-label {
  position: relative;
  width: 100%;
  max-width: 800px;
}

div.notice.floating-label {
  position: relative;
  width: 100%;
  max-width: 800px;
}

div.invalid.floating-label {
  margin-bottom: 36px;
  max-width: 800px;
  width: 100%;
  position: relative;
}

div.help.floating-label {
  position: relative;
  width: 100%;
  max-width: 800px;
}

div.form-group {
  position: relative;
  max-width: 800px;
}

div.checkboxes {
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 800px;
}

div.radiobuttons-list {
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 800px;
}

/* Selectbox*/

div.selectbox {
  position: relative;
  max-width: 800px;
}

/* End Selectbox*/

h3 {
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 18px 0;
  max-width: 790px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01rem;
  word-spacing: 0.025rem;
  color: rgba(0, 0, 0, 1);
  width: calc(100% - 100px);
}

button.go-next {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  line-height: 120%;
  background-color: rgba(78, 78, 78, 1);
  display: inline-block;
  height: 49px;
  border-radius: 49px;
  border: none;
  padding: 12px 32px 12px 0px;
  vertical-align: middle;
  margin: 15px 30px 15px 0;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

button.go-back {
  overflow: hidden;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  line-height: 120%;
  color: rgba(78, 78, 78, 1);
  background-color: rgba(255, 255, 255, 1);
  display: inline-block;
  height: 49px;
  border-radius: 49px;
  border: 1px solid #4E4E4E;
  padding: 12px 0px 12px 32px;
  vertical-align: middle;
  margin: 15px 30px 15px 0;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

input.valid {
  padding-right: 72px !important;
}

img.valid {
  position: absolute;
  content: "";
  height: 31px;
  width: 31px;
  right: 16px;
  top: 33px;
}

input.notice {
  padding-right: 72px !important;
}

img.notice {
  position: absolute;
  content: "";
  height: 31px;
  width: 31px;
  right: 16px;
  top: 33px;
}

img.invalid {
  position: absolute;
  content: "";
  height: 31px;
  width: 31px;
  right: 16px;
  top: 33px;
}

input.invalid {
  padding-right: 72px !important;
  color: rgba(234, 22, 31, 1);
}

img.help {
  position: absolute;
  content: "";
  height: 31px;
  width: 31px;
  right: 16px;
  top: 33px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

input.help {
  padding-right: 72px !important;
}

label.floating-label {
  position: absolute;
  font-size: 13px;
  line-height: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  opacity: 1;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
  top: 0;
  left: 30px;
  background-color: #fff;
  padding: 2px 5px;
}

span.error {
  position: absolute;
  display: inline-block;
  color: rgba(234, 22, 31, 1);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  top: 80px;
  left: 0;
}

label.checkbox {
  position: relative;
  display: inline-block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-right: 36px;
  padding-left: 36px;
  margin-bottom: 18px;
}

label.radio-block {
  width: 100%;
  position: relative;
  display: block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding-left: 36px;
  margin-bottom: 15px;
  margin-top: 15px;
}

span.optional {
  position: absolute;
  display: inline-block;
  transform: translateY(8px);
  transition: all 250ms ease-out;
  top: 0;
  right: 30px;
  background-color: #fff;
  padding: 2px 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
}

div.select {
  height: 59px;
  border: 1px solid #707070;
  margin: 20px 0;
  padding: 17px 70px 13px 36px;
  color: rgba(112, 112, 112, 1);
  width: 100%;
  max-width: 800px;
}

img.select {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  right: 44px;
  top: 21px;
}

img.help:hover, img.help:focus {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}


@media screen and (max-width: 1039px) {

  h3 {
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    margin: -3px 0 12px -1px;
  }

}

@media screen and (max-width: 767px) {

  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: -2px 0 12px 0px;
  }

  button.go-next {
    width: 100%;
    margin-right: 0;
  }

  button.go-back {
    width: 100%;
    margin-right: 0;
  }

}


/* End Form Elements*/

/* Horizontal Line*/
div.hr-dln-start {
  border-top: 1px solid #B6B6B6;
  height: 1px;
  margin: 29px 0 24px;
  width: 100%;
}


@media screen and (max-width: 767px) {

  div.hr-dln-start {
    display: none;
  }

}

/*End horizontal line*/

/* Text start */
p.intro {
  color: rgba(0,0,0,1);
  font-family: 'Roboto'; font-style: normal; font-weight: 500;
  margin-bottom: 18px;
  margin-top: -4px;
  max-width: 790px;
  font-size: 18px; line-height: 28px;
}

p {
  color: rgba(0,0,0,1);
  font-family: 'Roboto'; font-style: normal; font-weight: 300;
  margin-bottom: 15px;
  margin-top: -2px;
  max-width: 790px;
  font-size: 16px; line-height: 26px;
}
/* Text end */

/* Button */

button.primary {
  font-family: 'Roboto'; font-style: normal; font-weight: 500;
  font-size: 16px; line-height: 26px;
  line-height: 120%;
  background-color: rgba(78,78,78,1);
  color: rgba(255,255,255,1);
  text-align:center;
  display: inline-block;
  height: 49px;
  border-radius: 49px;
  border: none;
  padding: 12px 40px;
  vertical-align: middle;
  margin: 15px 30px 15px 0;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

button.primary:hover, button.primary:focus {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

button.secondary {
  font-family: 'Roboto'; font-style: normal; font-weight: 500;
  font-size: 16px; line-height: 26px;
  line-height: 120%;
  color: rgba(78,78,78,1);
  background-color: rgba(255,255,255,1);
  display: inline-block;
  height: 49px;
  border-radius: 49px;
  border: 1px solid #4E4E4E;
  padding: 12px 39px 12px 44px ;
  vertical-align: middle;
  margin: 15px 30px 15px 0;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  text-align:center;
}

button.secondary:hover, button.secondary:focus {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

button.go-back {
  overflow:hidden;
  text-align:center;
  font-family: 'Roboto'; font-style: normal; font-weight: 500;
  font-size: 16px; line-height: 26px;
  line-height: 120%;
  color: rgba(78,78,78,1);
  background-color: rgba(255,255,255,1);
  display: inline-block;
  height: 49px;
  border-radius: 49px;
  border: 1px solid #4E4E4E;
  padding: 12px 0px 12px 32px ;
  vertical-align: middle;
  margin: 15px 30px 15px 0;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}


@media screen and (max-width: 767px) {

  button.go-back {
    width:100%;
    margin-right: 0;
  }

}

button.go-back img:first-of-type {
  transform: translate(0px,0px);
  opacity: 1;
}

button.go-back img:last-of-type {
  transform: translate(35px,0px);
  opacity: 0;
}

button.go-next *, button.go-back *, button.submit * {
  transition: all 250ms ease-in-out;
  display: inline-block;
}

button.go-back span {
  transform: translate(5px,0px);
}

button.go-next *, button.go-back *, button.submit * {
  transition: all 250ms ease-in-out;
  display: inline-block;
}


button.go-next img:first-of-type, button.submit img:first-of-type {
  transform: translate(-35px,0px);
  opacity:0;
}
button.go-next span, button.submit span {
  transform: translate(0px,0px);
}
button.go-next img:last-of-type, button.submit img:last-of-type {
  transform: translate(0px,0px);
  opacity:1;
}
button.go-next:hover img:first-of-type, button.submit:hover img:first-of-type {
  transform: translate(32px,0px);
  opacity:1;
}
button.go-next:hover span, button.submit:hover span {
  transform: translate(35px,0px);
}
button.go-next:hover img:last-of-type, button.submit:hover img:last-of-type {
  transform: translate(65px,0px);
  opacity:0;
}


button.go-back img:first-of-type {
  transform: translate(0px,0px);
  opacity:1;
}
button.go-back span {
  transform: translate(5px,0px);
}
button.go-back img:last-of-type {
  transform: translate(35px,0px);
  opacity:0;
}
button.go-back:hover img:first-of-type {
  transform: translate(-70px,0px);
  opacity:0;
}
button.go-back:hover span {
  transform: translate(-35px,0px);
}
button.go-back:hover img:last-of-type {
  transform: translate(-30px,0px);
  opacity:1;
}

button.submit {
  overflow:hidden;
  text-align:center;
  font-family: 'Roboto'; font-style: normal; font-weight: 500;
  font-size: 16px; line-height: 26px;
  line-height: 120%;
  background-color: rgba(78,78,78,1);
  color: rgba(255,255,255,1);
  display: inline-block;
  height: 49px;
  border-radius: 49px;
  border: none;
  padding: 12px 32px 12px 0px;
  vertical-align: middle;
  margin: 15px 30px 15px 0;
  transition: all 250ms ease-in-out;
  cursor:pointer;
}


/* End Button */
